<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Parameter Pemeriksaan Klinik'">
          <template v-slot:body v-if="dataLoaded">
            <Form :form="form" :route="'fields/' + form.id" purpose="edit" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/masters/fields/Form.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {},
      // Other
      dataLoaded: false,
    };
  },

  methods: {
    async get() {
      this.form = await module.get("fields/" + this.$route.params.id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/masters/fields/list");
      } else {
        this.form["_method"] = "put";
        this.dataLoaded = true;
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Parameter Pemeriksaan Klinik", route: "" },
      { title: "Daftar Parameter Pemeriksaan Klinik", route: "/masters/fields/list" },
      { title: "Tambah" },
    ]);
    // Get Data
    this.get();
  },
};
</script>